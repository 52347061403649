<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="team_id"
                  :not-list="false"
                  :options="teamList"
                  :title="$t('scope.Team') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  :isOpenMultiselect="true"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="company_id"
                  :not-list="false"
                  :options="customerOptions"
                  :title="$t('order.customer') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_company_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="represantative_id"
                  :not-list="false"
                  :options="represantatives"
                  :title="$t('Müşteri Temsilcisi') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="represantative_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="order_id"
                  :not-list="false"
                  :options="orderOptions"
                  :title="$t('report.order_no') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_id"
                ></custom-multi-select>
              </div>
            </div>
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-4 col-md-6 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div
          :class="[resizeChartValue == 'piechart' ? 'col-12' : 'col-6']"
          v-if="resizeChartValue != 'linechart'"
        >
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <profit-and-loss-percent-bar-report
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :currency-rate-options="currencyRateOptions"
                :onClickButton="onClickButton"
                :filters.sync="tempFilters"
                @resizecard="resizeCard"
              ></profit-and-loss-percent-bar-report>
            </template>
          </dashboard-box>
        </div>
        <div
          :class="[resizeChartValue == 'linechart' ? 'col-12' : 'col-6']"
          v-if="resizeChartValue != 'piechart'"
        >
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <profit-and-loss-bar-report
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :currency-rate-options="currencyRateOptions"
                :onClickButton="onClickButton"
                :filters.sync="tempFilters"
                @resizecard="resizeCard"
              ></profit-and-loss-bar-report>
            </template>
          </dashboard-box>
        </div>
      </div>

      <dashboard-box>
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="fields"
              :hover="false"
              :items="formattedReport"
              :no-border-collapse="true"
              :outlined="false"
              :striped="true"
              :disabled-infinite-loading="false"
              :infiniteId="infiniteId"
              :sticky-header="true"
              tableVariant="''"
              :page.sync="page"
              :perPage.sync="perPage"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              :responsive="true"
              @onClickView="onClickView"
            ></data-table>
          </div>
        </template>
      </dashboard-box>
      <div class="row"> 
        <div
          class="col-12 info-total-area align-items-center d-flex align-content-end"
        >
          <div class="col-1 text-center mr-25">
            <span class="total-area-text">{{ $t("general.total") }}</span>
          </div>
          <div class="col-2 text-center mr-2"> 
            <span class="total-area-text">
              {{
                Number(totalData.bottom_total_endorsement_euro).toLocaleString(
                  "tr-TR",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )+ " €"
              }}
            </span>
          </div>
          <div class="col-2 text-center mr-2"> 

            <span class="total-area-text">
              {{
                Number(totalData.bottom_total_endorsement_tl).toLocaleString(
                  "tr-TR",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )+ " ₺"
              }}
            </span>
          </div>
          <div class="col-2 text-center mr-2"> 
            <span class="total-area-text">
              {{
                Number(totalData.bottom_total_manifacture).toLocaleString(
                  "tr-TR",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )+ " ₺"
              }}
            </span>
          </div>
          <div class="col-2 text-center mr-2"> 
            <span class="total-area-text">
              {{
                Number(
                  totalData.bottom_total_profit_and_lossTL
                ).toLocaleString("tr-TR", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })+ " ₺"
              }}
            </span>
          </div>
          <div class="col-1 text-center mr-0"> 
            <span class="total-area-text">
              {{
                Number(
                  totalData.bottom_total_ratio
                ).toLocaleString("tr-TR", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })+ "%"
              }}
            </span>
          </div>
        </div>
      </div>
    </div> 
    <b-modal id="select-company-modal" ref="select-company-modal" hide-header hide-header-close
      scrollable size="lg"> 
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="orderFields"
              :hover="false"
              :items="profit_order_list"
              :no-border-collapse="true"
              :outlined="false"
              :striped="true"
              :disabled-infinite-loading="false"
              :infiniteId="infiniteId"
              :sticky-header="true"
              tableVariant="''"
              :page.sync="page"
              :perPage.sync="perPage" 
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              :responsive="true" 
            ></data-table>
          </div> 
      <template #modal-footer>
        <div class="col-12 text-center">
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeSelectCompanyModal">
            {{ $t('general.close') }}
          </button>
        </div>
      </template>
    </b-modal> 
  </div>

  


</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import ProfitAndLossPercentBarReport from "./ProfitAndLossPercentBarReport";
import ProfitAndLossBarReport from "./ProfitAndLossBarReport";

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER,
} from "@/core/services/store/report/endorsementReport.module";

import {
  GET_ITEMS,
  GET_ITEMS as REST_GET_ITEM,
} from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  ENDORSEMENT_REPORT_START_DATE_STORAGE_NAME,
  ENDORSEMENT_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "ProfitAndLossReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    ContextMenu,
    ProfitAndLossPercentBarReport,
    ProfitAndLossBarReport,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    onClickResizeControl() {
      if (this.resizeBool != true) {
        return this.resize();
      } else {
        return;
      }
    },
    formattedReport() {
      if (
        !this.items ||
        this.items.data === null ||
        this.items.data === undefined
      )
        return [];
      let temp = [];
      this.totalData.bottom_total_endorsement_euro = 0;
      this.totalData.bottom_total_manifacture = 0;
      this.totalData.bottom_total_endorsement_tl = 0;
      this.totalData.bottom_total_profit_and_lossTL = 0;
      this.totalData.bottom_total_ratio = 0;
      
      this.items.data.forEach((item) => { 
  
        let endorsement_euro =   Number(item.endorsement_euro ?? 0);
        let manifacture =  Number(item.manifacture_total ?? 0);
        let endorsement_tl =   Number(item.endorsement_tl_total ?? 0);
        let profit_and_lossTL =   endorsement_tl - manifacture;
        let ratio = profit_and_lossTL / (endorsement_tl ?? 1)*100 

        if(endorsement_tl > 0){

          this.totalData.bottom_total_endorsement_euro += Number(endorsement_euro);
          this.totalData.bottom_total_manifacture += Number(manifacture);
          this.totalData.bottom_total_endorsement_tl += Number(endorsement_tl); 
          this.totalData.bottom_total_profit_and_lossTL += Number(profit_and_lossTL);
          this.totalData.bottom_total_ratio = this.totalData.bottom_total_profit_and_lossTL / (this.totalData.bottom_total_endorsement_tl ?? 1)*100 ;

          temp.push({
            customer: item.company_name ?? '',
            company_id: item.company_id ?? '',
            total_endorsement_euro:
              endorsement_euro.toLocaleString("tr-TR") + " €",
            total_endorsement_tl:
              endorsement_tl.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " ₺",
            manufacturing_expense:
              manifacture.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " ₺",
            profit_and_loss: 
            profit_and_lossTL.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " ₺",
            ratio:   ratio.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + " %",
          });
        }
      });
      return temp;
    },   
  },
  data() { 
    return {
      // Filters & pagination
      totalAreaLoading: false,
      infiniteId: 1,
      sort: "desc",
      order: "id",
      resizeChartValue: null,
      sampleStatuses: [],
      fieldsToViewEdit: [],
      customerOptions: [],
      search: null,
      onClickButton: false,
      paginate: [],
      page: 1,
      perPage: 25,
      currentStoreType: 99999999,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      download: false,
      reportListUrl: "api/report/profit-and-loss-report",
      currencyRateOptions: [],
      companies: [],
      orderOptions: [],
      totalData: {
          bottom_total_endorsement_euro:0,
          bottom_total_manifacture:0,
          bottom_total_endorsement_tl:0,
          bottom_total_profit_and_lossTL:0,
          bottom_total_ratio:0
      },
      months: [
        {
          id: "01",
          name: this.$t("dateTime.january_full"),
        },
        {
          id: "02",
          name: this.$t("dateTime.february_full"),
        },
        {
          id: "03",
          name: this.$t("dateTime.march_full"),
        },
        {
          id: "04",
          name: this.$t("dateTime.april_full"),
        },
        {
          id: "05",
          name: this.$t("dateTime.may_full"),
        },
        {
          id: "06",
          name: this.$t("dateTime.june_full"),
        },
        {
          id: "07",
          name: this.$t("dateTime.july_full"),
        },
        {
          id: "08",
          name: this.$t("dateTime.august_full"),
        },
        {
          id: "09",
          name: this.$t("dateTime.september_full"),
        },
        {
          id: "10",
          name: this.$t("dateTime.october_full"),
        },
        {
          id: "11",
          name: this.$t("dateTime.november_full"),
        },
        {
          id: "12",
          name: this.$t("dateTime.december_full"),
        },
      ],
      fields: [
        {
          key: "customer",
          label: this.$t("report.customer"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
          sortable: false,
        },
        {
          key: "total_endorsement_euro",
          label: this.$t("Toplam Ciro(€)"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "total_endorsement_tl",
          label: this.$t("Toplam Ciro(₺)"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "manufacturing_expense",
          label: this.$t("İmalat Gideri"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "profit_and_loss",
          label: this.$t("Kar-Zarar"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
        },
        {
          key: "ratio",
          label: this.$t("Oran"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "show_order_detail",
          label: this.$t("Show"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
      ],
      orderFields:[
        {
          key: "order_number",
          label: this.$t("report.order_number"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
          sortable: false,
        },
        {
          key: "total_endorsement_euro",
          label: this.$t("Toplam Ciro(€)"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "total_endorsement_tl",
          label: this.$t("Toplam Ciro(₺)"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "manufacturing_expense",
          label: this.$t("İmalat Gideri"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "profit_and_loss",
          label: this.$t("Kar-Zarar"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
        },
        {
          key: "ratio",
          label: this.$t("Oran"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
      ],
      teamList: [],
      team_id: [],
      represantative_id: [],
      company_id: [],
      order_id: [],
      tempFilters: [],
      represantatives: [],
      profit_order_list:[],
    };
  },
  methods: {
    resizeCard(val) {
      this.resizeChartValue = val;
    },
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    beforeFilterResult() {
      setTimeout(() => {
        this.filterResult();
      }, 100);
    },
    onContextMenu(event) {
      event.preventDefault();
    },
    filterResult() {
      localStorage.setItem(
        ENDORSEMENT_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        ENDORSEMENT_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
    }, 
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      if (this.team_id) {
        this.$set(filters, "team_id", JSON.stringify(this.team_id));
      }

      if (this.represantative_id) {
        this.$set(
          filters,
          "represantative_id",
          JSON.stringify(this.represantative_id)
        );
      }
      if (this.company_id) {
        this.$set(filters, "company_id", JSON.stringify(this.company_id));
      }
      if (this.order_id) {
        this.$set(filters, "order_id", JSON.stringify(this.order_id));
      }

      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);
      
      this.tempFilters = filters;

      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            this.infiniteId++;
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    resetFilters() {
      (this.sort = "desc"),
        (this.order = "id"),
        this.setFilter({
          page: 1,
          order_id: [],
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });
    },
    setup() {
      let self = this,
        promises = [];
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/companies",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/currency-rate",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/team-list",
        })
      );

      if (this.filters.represantative_id) {
        let url = "api/representative-list" + this.filters.represantative_id;
        promises.push(
          this.$store.dispatch(GET_ITEMS, {
            url: url,
          })
        );
      } else {
        let url = "api/representative-list";
        promises.push(
          this.$store.dispatch(GET_ITEMS, {
            url: url,
          })
        );
      }

      Promise.all(promises).then((results) => {
        if (results[0].status) {
          self.customerOptions = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "name"
          );
        }
        if (results[1].status) {
          self.currencyRateOptions = results[1].data.data;
        }
        if (results[2].status) {
          self.orderOptions = results[2].data;
        }
        if (results[3].status) {
          self.teamList = self.convertArrayToObjectByKey(
            results[3].data,
            "id",
            "user_name"
          );
        }
        if (results[4].status) { 
          self.tmpRep = self.convertArrayToObjectByKey(
            results[4].data,
            "id",
            "user_name"
          ); 
          self.represantatives = self.convertArrayToObjectByKey(
            results[4].data,
            "id",
            "user_name"
          );
        }
      });
    },
    onClickView(payload) {
      this.getProfitOrderItems(payload);
      this.openSelectCompanyModal()
      
    },
    openSelectCompanyModal() {
      this.$refs['select-company-modal'].show();
    },
    closeSelectCompanyModal() {
      this.profit_order_list = [];
      this.$refs['select-company-modal'].hide();
    },
    getProfitOrderItems(company_id) {
      let filter_list=[]; 
      this.$set(
        filter_list,
        "company_id",
        company_id
      ); 
      this.$set(
        filter_list,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filter_list,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filter_list,
          url: "api/report/profit-and-loss-order_list",
        })
        .then((result) => {       
          let temp = [];
          result.data.forEach((item) => { 
            let endorsement_euro =   Number(item.endorsement_euro ?? 0);
            let manifacture =  Number(item.manifacture_total ?? 0);
            let endorsement_tl =   Number(item.endorsement_tl_total ?? 0);
            let profit_and_lossTL =   endorsement_tl - manifacture;
            let ratio = profit_and_lossTL / (endorsement_tl ?? 1)*100 

            temp.push({
              order_number: item.order_number ?? '', 
              total_endorsement_euro:
                endorsement_euro.toLocaleString("tr-TR") + " €",
              total_endorsement_tl:
                endorsement_tl.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ₺",
              manufacturing_expense:
                manifacture.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ₺",
              profit_and_loss: 
              profit_and_lossTL.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " ₺",
              ratio:   ratio.toLocaleString("tr-TR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " %",
            });
          });
          this.profit_order_list = temp
        });
    },
    orderFilter(param){ 
      let params=''; 
      if(param.team_id){
        params += '&team_id='+JSON.stringify(param.team_id);
      }
      if(param.company_id ){
        params += '&company_id='+JSON.stringify(param.company_id);
      } 
        this.$store
          .dispatch(GET_ITEMS, {
            url: "api/report/order-list-packing?"+params, 
          })
          .then((result) => { 
            if (result.status) {
              this.orderOptions = result.data
            } else {
              console.log({ error: result });
            }
          });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("Kar-Zarar Raporu") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      ENDORSEMENT_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(ENDORSEMENT_REPORT_END_DATE_STORAGE_NAME))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      ENDORSEMENT_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(ENDORSEMENT_REPORT_START_DATE_STORAGE_NAME))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();
    this.onClickButton = true;
    setTimeout(this.filterResult, 500);
  },
  watch: {
    sort() {
      this.beforeFilterResult();
    },
    order() {
      this.beforeFilterResult();
    },
    "team_id"(value){ 
      this.orderFilter({'team_id':value})      
    },
    "company_id"(value){ 
      this.orderFilter({'company_id':value})      
    },
    "filters.team_id"(value) {
      this.represantatives = [];
      let members = this.teamMembers(value);
      if (members.length > 0) {
        this.represantatives = this.convertArrayToObjectByKey(
          members,
          "id",
          "user_name"
        );
      } else {
        this.represantatives = this.tmpRep;
      }
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}

.info-total-area {
  margin-bottom: 1%;
  margin-right: -1%;
  padding-right: 5%;
}
.info-total-area > div {
  flex: 0 0 17%; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.general-area {
  margin-bottom: 1%;
  padding-left: 2%;
}
.general-area > div {
  flex: 1; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.total-area-text {
  font-size: 13px;
  font-weight: 400;
}
</style>
<style lang="scss">
.b-table-sticky-header > .table.b-table > thead > tr > th {
  background-color: white !important;
}
.b-table-sticky-header {
  max-height: 500px;
}
.b-table-sticky-header > .table.b-table > tbody > tr > th {
  background-color: white !important;
}
.vdpPositionTop.vdpPositionLeft {
  z-index: 3;
}
</style>
