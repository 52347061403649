<template>
  <div>
    <button @click="changeChart" class="btn btn-success btn-sm mb-3">      
      {{ change_chart ?  $t("Ekip Grafiği") : $t("Müşteri Grafiği") }}
    </button>
    <p class="second-quality-title mb-0 pb-0 d-flex mx-3">
      {{ change_chart ?  $t("Müşteri Kar-Zarar Oranı") : $t("Ekip Kar-Zarar Oranı") }}
      <button-with-icon
        v-if="resizeBool == null || resizeBool == false"
        icon-name="icons.report.enlarge"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
      <button-with-icon
        v-if="resizeBool == true"
        icon-name="icons.report.reduce"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
    </p>
    <div
      class="row loading-area text-center justify-content-center align-items-center"
      v-if="chartLoading"
    >
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!change_chart">
      <div
        v-if="!chartLoading"
        v-on:click="resizeBool ? null : resize()"
        :class="{ 'graphic-container': resizeBool }"
        class="mt-0 pt-0"
      >
        <apexchart
          type="bar"
          :height="height"
          :options="chartOptions"
          :series="series"
          :width="tmpWidth"
        ></apexchart>
      </div>
    </div>
    <div v-if="change_chart">
      <div
        v-if="!chartLoading"
        v-on:click="resizeBool ? null : resize()"
        class="mt-0 pt-0"
      >
        <apexchart
          type="bar"
          :height="height"
          :options="chartOptionsCompany"
          :series="seriesCompany"
          :width="tmpWidth"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_ITEMS } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
export default {
  name: "ProfitAndLossBarReport",
  components: {
    apexchart: VueApexCharts,
    ButtonWithIcon,
  },
  data() {
    return {
      height: 350,
      width: null,
      chartCategories: [],
      chartCategoriesCompany: [],
      chartItems: [],
      chartItemsCompany: [],
      tmpSeries: [],
      series: [],
      seriesCompany: [],
      chartLoading: false,
      resizeBool: false,
      getDataStatus: false,  
      change_chart:false,    
    };
  },
  computed: {
    lineLength() {},
    chartOptions() {
      let seriesLength = 0.99;
      if (this.resizeBool) {
        this.tmpWidth = window.innerWidth * seriesLength;
      } else {
        let widhMiniScreen = 0;
        if (window.innerWidth <= 1500) {
          widhMiniScreen = 2.8;
        } else {
          widhMiniScreen = 3.1;
        }
        this.tmpWidth = window.innerWidth / widhMiniScreen;
      }
      let self = this;
      let tmpChartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },   
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "₺";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#fff"]
          }
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 1000000000,
                to: 0,
                color: '#00ff00'
              }, {
                from: -1000000000,
                to: 0,
                color: '#ff0000'
              }]
            },
            columnWidth: '80%',
          }
        },
        stroke: {
          show: true, 
          colors: ['transparent']
        },
        xaxis: {
          categories: this.chartCategories,
        },
        yaxis: {
          title: {
            text: 'TL'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) { 
              return val.toLocaleString("tr-TR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })  + " ₺"
            }
          }
        }
      };
      return tmpChartOptions;
    },
    chartOptionsCompany() {
      let seriesLength = this.series[0].data.length / 30;
      if (seriesLength <= 1) {
        seriesLength = 0.99;
      }
      if (this.resizeBool) {
        this.tmpWidth = window.innerWidth * seriesLength;
      } else {
        let widhMiniScreen = 0;
        if (window.innerWidth <= 1500) {
          widhMiniScreen = 2.8;
        } else {
          widhMiniScreen = 3.1;
        }
        this.tmpWidth = window.innerWidth / widhMiniScreen;
      }
      let self = this;
      let tmpChartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },   
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val + "₺";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#fff"]
          }
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: 1000000000,
                to: 0,
                color: '#00ff00'
              }, {
                from: -1000000000,
                to: 0,
                color: '#ff0000'
              }]
            },
            columnWidth: '80%',
          }
        },
        stroke: {
          show: true, 
          colors: ['transparent']
        },
        xaxis: {
          categories: this.chartCategoriesCompany,
        },
        yaxis: {
          title: {
            text: 'TL'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) { 
              return val.toLocaleString("tr-TR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })  + " ₺"
            }
          }
        }
      };
      return tmpChartOptions;
    }, 
  },
  props: [
    "startDate",
    "endDate",
    "currencyRateOptions", 
    "onClickButton",
    "filters",
  ],
  mounted() {
    this.getItems();
    this.updateColors();
  },
  methods: {
    changeChart(){
      this.change_chart = !this.change_chart
    },
    resize() {
      let seriesLength = this.series[0].data.length / 30;

      if (seriesLength <= 1) {
        seriesLength = 0.8;
      }
      this.resizeBool = !this.resizeBool;
      if (this.resizeBool) {
        this.height = 400;
        this.tmpWidth = window.innerWidth * seriesLength;

        this.$emit("resizecard", "linechart");
      } else {
        let widthMiniScreen = 0;
        if (window.innerWidth <= 1500) {
          widthMiniScreen = 2.8;
        } else {
          widthMiniScreen = 3.1;
        }
        this.height = 350;
        this.tmpWidth = window.innerWidth / widthMiniScreen;
        this.$emit("resizecard", null);
      }
    },
    getItems() {
      this.chartLoading = true;

      //teams
      this.series = []; 
      this.chartCategories = [];
      this.profit_loss = [];
      this.endorsement = [];

      //companies
      this.seriesCompany = [];
      this.chartCategoriesCompany = [];
      this.profit_loss_company = [];
      this.endorsementCompany = [];


      let filters = this.filters;
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.endDate).format(LARAVEL_DATE_FORMAT)
      );

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/profit-and-loss-bar-chart-report",
        })
        .then((result) => {
          this.chartLoading = false;
          if (result.status) {
            let tmpData = result.data;
            let self = this;

             if (result.status) {
              this.chartItems = result.data.chart_items; 
               
              this.chartItems.forEach((chartData, key) => {
                let profit_loss= parseFloat(chartData.profit_loss.replace(/\./g, '').replace(',', '.'))
                let endorsement= parseFloat(chartData.endorsement.replace(/\./g, '').replace(',', '.'))
                self.chartCategories.push(chartData.name);
                self.profit_loss.push(profit_loss);
                self.endorsement.push(endorsement);
              });
              this.series = [
                {
                  name: "Kar-Zarar",
                  data: this.profit_loss,
                  color: "#2BAA1E",
                },
                {
                  name: "Toplam Ciro",
                  data: this.endorsement,
                  color: "#6563A4",
                },
              ]; 

              this.chartItemsCompany = result.data.company_chart_items; 
              this.chartItemsCompany.forEach((chartData, key) => {
                let profit_loss= parseFloat(chartData.profit_loss.replace(/\./g, '').replace(',', '.'))
                let endorsement= parseFloat(chartData.endorsement.replace(/\./g, '').replace(',', '.'))
                self.chartCategoriesCompany.push(chartData.name);
                self.profit_loss_company.push(profit_loss);
                self.endorsementCompany.push(endorsement);
              });
              this.seriesCompany = [
                {
                  name: "Kar-Zarar",
                  data: this.profit_loss_company,
                  color: "#2BAA1E",
                },
                {
                  name: "Toplam Ciro",
                  data: this.endorsementCompany,
                  color: "#6563A4",
                },
              ];                 
            } else {
              let tmpresult = result.data.response.data;
              this.sweetAlertError(tmpresult.message);
            }
          }
        });
    },
    updateColors() {
      if(this.series[0]){
        const colors = this.series[0].data.map(value => (value >= 0 ? '#00ff00' : '#ff0000'));
        this.chartOptions.plotOptions.bar.colors.ranges = colors.map((color, index) => ({
          from: index,
          to: index,
          color: color,
        }));
      }
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        if (this.chartLoading != true) {
          this.series = [];
          this.Company = [];
          this.getItems();
        }
      }
    },
  },
};
</script>
<style scoped>
.second-quality-title {
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  margin-top: 5px;
  color: #5cb7e0;
  justify-content: space-between;
}
.graphic-container {
  overflow-x: scroll;
  display: flex;
  position: relative;
}
.button-open-close {
  border: 0px !important;
  width: 20px;
}
.loading-area {
  height: 350px;
}
</style>
